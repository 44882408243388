import React from "react";
import { navigate } from "gatsby";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { getFinancialYearFirstYear } from "../../utils/helpers";
import Moment from "moment";

class PenaltyUnitsCalculator extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      jurisdiction: props.jurisdictionsQueryResults.data.allJurisdictions.find(e => e.name === props.jurisdictionQueryString) ?? props.jurisdictionsQueryResults.data.allJurisdictions[0],
      maximumPenaltyUnits: isNaN(props.maximumPenaltyUnitsQueryString) ? undefined : Number(props.maximumPenaltyUnitsQueryString),
      applicableFinancialYears: [
        {
          id: 2019,
          name: "2019/20"
        },
        {
          id: 2018,
          name: "2018/19"
        },
        {
          id: 2017,
          name: "2017/18"
        },
        {
          id: 2016,
          name: "2016/17"
        },
        {
          id: 2015,
          name: "2015/16"
        }
      ],
      financialYear: isNaN(props.financialYearQueryString) ? undefined : Number(props.financialYearQueryString),
      validation:
      {
        isJurisdictionValid: true,
        isMaximumPenaltyUnitsValid: true,
        isFinancialYearValid: true
      }
    };
  }

  updateApplicableFinancialYears(jurisdiction)
  {
    if (!jurisdiction.id || jurisdiction.id === null)
    {
      return;
    }

    const firstFinancialYearFirstYear = getFinancialYearFirstYear(
      jurisdiction.penaltyUnitMultipliers
        .sort((a, b) => Moment(a.commencementDate, 'YYYY-MM-DD') - Moment(b.commencementDate, 'YYYY-MM-DD'))
        [0]
        .commencementDate
    );
    const currentFinancialYearFirstYear = Moment().month() >= 6 ? Moment().year() : Moment().year() - 1;

    const applicableFinancialYears = [];

    for (let i = currentFinancialYearFirstYear; i >= firstFinancialYearFirstYear; --i)
    {
      applicableFinancialYears.push(
        {
          id: i,
          name: `${i}/${(i + 1).toString().slice(-2)}`
        }
      );
    }

    this.setState(
      {
        jurisdiction: jurisdiction,
        applicableFinancialYears: [...applicableFinancialYears],
        financialYear: applicableFinancialYears.some(afy => this.checkFinancialYear(afy, this.state.financialYear)) ? this.state.financialYear : undefined
      }
    );
  }

  checkFinancialYear(applicableFinancialYear, selectedFinancialYear)
  {
    return applicableFinancialYear.id === selectedFinancialYear;
  }

  handleSubmit(e)
  {
    e.preventDefault();

    let isJurisdictionValid = true;
    let isMaximumPenaltyUnitsValid = true;
    let isFinancialYearValid = true;

    // validate
    if (this.state.jurisdiction.id === null)
    {
      isJurisdictionValid = false;
    }

    if (!this.state.maximumPenaltyUnits)
    {
      isMaximumPenaltyUnitsValid = false;
    }

    if (!this.state.financialYear)
    {
      isFinancialYearValid = false;
    }

    this.setState(
      {
        validation:
        {
          isJurisdictionValid: isJurisdictionValid,
          isMaximumPenaltyUnitsValid: isMaximumPenaltyUnitsValid,
          isFinancialYearValid: isFinancialYearValid
        }
      }
    );

    if (isJurisdictionValid
      && isMaximumPenaltyUnitsValid
      && isFinancialYearValid)
    {
      navigate(`/tools${ this.props.isWidget ? "/widgets" : "" }/penaltyunits/result?j=${ this.state.jurisdiction.name }&mpu=${ this.state.maximumPenaltyUnits }&fy=${ this.state.financialYear }`);
    }
  }

  changeJurisdiction(e)
  {
    this.updateApplicableFinancialYears(e);
  }

  componentDidMount()
  {
    if (this.state.jurisdiction.id !== null)
    {
      this.updateApplicableFinancialYears(this.state.jurisdiction);
    }
  }

  render()
  {
    return (
      <div>
        <form
          onSubmit = { e => this.handleSubmit(e) }
        >
          <div
            className = "mb-6"
          >
            <label
              className="block font-bold mb-2 text-xs uppercase"
              htmlFor="jurisdiction"
            >
              Jurisdiction
            </label>

            <Select
              id = "jurisdiction"
              className = "appearance-none block px-3 py-2 rounded-md text-gray-700 w-full border-gray-400"
              options = { this.props.jurisdictionsQueryResults.data.allJurisdictions }
              getOptionLabel = { option => option.name }
              getOptionValue = { option => option.id }
              defaultValue = { this.state.jurisdiction }
              onChange = { e => this.changeJurisdiction(e) }
            />
            {
              !this.state.validation.isJurisdictionValid &&
              <p
                className = "px-3 text-red-500 text-xs italic"
              >
                Please choose a jurisdiction.
              </p>
            }
          </div>

          <div
            className = "mb-6"
          >
            <label
              className="block font-bold mb-2 text-xs uppercase"
              htmlFor="maximumPenaltyUnits"
            >
              Maximum Penalty Units
            </label>

            <div
              className = "px-3 py-2"
            >
              <NumberFormat
                id="maximumPenaltyUnits"
                className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
                defaultValue = { this.state.maximumPenaltyUnits }
                thousandSeparator = { true }
                inputMode = "numeric"
                onChange =
                {
                  e =>
                  {
                    this.setState(
                      {
                        maximumPenaltyUnits: Number(e.target.value.replace(/,/g, '')),
                      }
                    ); 
                  }
                }
              />
            </div>

            {
              !this.state.validation.isMaximumPenaltyUnitsValid &&
              <p
                className = "px-3 text-red-500 text-xs italic"
              >
                Please enter a number.
              </p>
            }
          </div>

          <div
            className = "mb-6"
          >
            <label
              className="block font-bold mb-2 text-xs uppercase"
              htmlFor="dateOfOffending"
            >
              In which financial year did the offending occur?
            </label>

            <div
              className = "flex flex-col flex-wrap h-96 sm:h-84 md:h-64 lg:h-48 xl:h-40 px-3 py-2"
            >
              {
                this.state.applicableFinancialYears.map(
                  input => (
                    <label
                      className= "block w-1/2 sm:w-1/3 lg:w-1/4 xl:w-1/6"
                      key = { `label${input.id}` }
                    >
                      <input
                        className = "mr-2 leading-tight"
                        key = { `input${input.id}` }
                        type = "radio"
                        name = { input.id }
                        value = { input.id }
                        checked = { this.state.financialYear === input.id }
                        onChange = {
                          e =>
                          {
                            this.setState(
                              {
                                financialYear: Number(e.target.value)
                              }
                            );
                          }
                        }
                      />
                      <span
                        className = "font-bold mb-2 text-xs uppercase"
                        key = { `span${input.id}` }
                      >
                        { input.name }
                      </span>
                    </label>
                  )
                )
              }
            </div>

            {
              !this.state.validation.isFinancialYearValid &&
              <p
                className = "px-3 text-red-500 text-xs italic"
              >
                Please select a Financial Year.
              </p>
            }
          </div>
    
          <div
            className = "text-center sm:text-left"
          >
            <button
              className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white"
            >
              Calculate
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default PenaltyUnitsCalculator;