import React from "react";
import Skeleton from 'react-loading-skeleton';

const PenaltyUnitsCalculatorSkeleton = () =>
{
  return (
    <div>
      <div className = "mb-6">
        <div className = "h-3 w-1/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-2/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-8 w-full">
          <Skeleton className = "h-full" />
        </div>
      </div>
      <div className = "mb-6">
        <div className = "h-3 w-3/6 mb-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "flex flex-col flex-wrap h-96 sm:h-84 md:h-64 lg:h-48 xl:h-40">
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
          <div className = "h-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <Skeleton className = "h-full" />
          </div>
        </div>
      </div>
      <div className = "h-8 w-1/6">
        <Skeleton className = "h-full" />
      </div>
    </div>
  );
};

export default PenaltyUnitsCalculatorSkeleton;